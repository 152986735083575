// extracted by mini-css-extract-plugin
export var column = "BlogNewsletter__column__O4DdH";
export var columnOrientation = "BlogNewsletter__columnOrientation__hS_k3";
export var container = "BlogNewsletter__container__uS9oC";
export var email = "BlogNewsletter__email__t_BcQ";
export var flatBorder = "BlogNewsletter__flatBorder__FqOFD";
export var form = "BlogNewsletter__form__TIuxZ";
export var header = "BlogNewsletter__header__jOqlG";
export var icon = "BlogNewsletter__icon__Fl8zR";
export var leftContent = "BlogNewsletter__leftContent__jyO5H";
export var resultMessage = "BlogNewsletter__resultMessage__X3vk0";
export var rightContent = "BlogNewsletter__rightContent__ORFgs";
export var roundBorder = "BlogNewsletter__roundBorder__U9JHu";
export var row = "BlogNewsletter__row__m1hAm";
export var rowOrientation = "BlogNewsletter__rowOrientation__Y61a_";
export var section = "BlogNewsletter__section__nxkxR";